import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const BannerIntro = ({ imageUrl, headline, copy, altText }) => {
  return (
    <>
      <div className="banner">
        <GatsbyImage
          image={imageUrl}
          alt={altText}
        />
      </div>
      <div className="container">
        <div className="intro">
          <h1>{headline}</h1>
          <p className="introtxt">{copy}</p>
        </div>
      </div>
    </>
  )
}

export default BannerIntro
