import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Button from "../components/Button"
import BannerIntro from "../components/BannerIntro"
import Divider from "../components/Divider"
import Seo from "../components/Seo"
import { GatsbyImage } from "gatsby-plugin-image"

const Service = (props) => {
  const pageData = props.data.wpService
  const seoData = props.pageContext

  return (
    <Layout extraClass="service">
      <Seo
        title={seoData.seoTitle}
        description={seoData.seoDesc}
        canonical={seoData.seoUrl}
        keywords={seoData.seoKeywords}
      />
      <BannerIntro
        imageUrl={
          pageData.singleService.bannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        altText={pageData.singleService.bannerImage.altText}
        headline={pageData.title}
        copy={pageData.singleService.copy}
      />
      <Divider />
      <div className="production-types">
        <div className="container">
          <h2>{pageData.singleService.title}</h2>
          <Button link="/request-rates" title="Request Rates" />
          <div className="all-productions">
            {pageData.singleService.production.map((prod) => (
              <div className="production">
                <h3>{prod.name}</h3>
                <p>{prod.copy}</p>
              </div>
            ))}
          </div>
          <GatsbyImage
            image={
              pageData.singleService.rightImage.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={pageData.singleService.rightImage.altText}
            className="right-img"
          />
          <ul className="other-prods">
            <li>Sporting Events</li>
            <li>Private Parties</li>
            <li>Marathons</li>
            <li>Gameshows</li>
            <li>Celebrity Events</li>
            <li>and More!</li>
          </ul>
        </div>
      </div>
      <Divider />
      <div className="featured-services">
        <div className="container">
          <h2>Featured Services</h2>
          <div className="contain">
            {props.data.allWpService.edges.map((pie2) => (
              <a
                href={`/services/${pie2.node.slug}`}
                className="service hvr-bob"
              >
                <img
                  src={pie2.node.singleService.icon.sourceUrl}
                  alt={pie2.node.singleService.icon.altText}
                />
                <h3>{pie2.node.title}</h3>
                <p>{pie2.node.singleService.smallBlurb}</p>
                <p className="red hover hover-1">Learn More</p>
              </a>
            ))}
          </div>
          <Button link="/services" title="View all services" />
        </div>
      </div>
    </Layout>
  )
}

export default Service

export const singleServiceQuery = graphql`
  query singleServiceQuery($id: String!) {
    allWpService(limit: 4) {
      edges {
        node {
          title
          slug
          singleService {
            smallBlurb
            icon {
              altText
              sourceUrl
            }
          }
        }
      }
    }
    wpService(id: { eq: $id }) {
      databaseId
      title
      singleService {
        bannerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        buttonLink
        buttonName
        copy
        headline2
        production {
          copy
          name
        }
        rightImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
